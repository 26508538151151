import React from "react";

// Components
import TextSection from "../components/TextSection";
import AboutUsImages from "../components/AboutUsImages";

// Data
import { aboutUsData } from "../data/aboutUsData";
import { aboutUsImages } from "../data/aboutUsImage";

export default function AboutUs() {
    return (
        <>
            <TextSection isLongText={true} data={aboutUsData} />
            <AboutUsImages data={aboutUsImages} />
        </>
    );
}
