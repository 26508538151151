import React from "react";

// Components
import TextSection from "../components/TextSection";
import Stacks from "../components/Stacks";
import { aboutUsData } from "../data/aboutUsData";
import { stacksData } from "../data/stacksData";

// Data

export default function IndividualAboutUs() {
    const currentPage = window.location.pathname.substring(1);
    const data = aboutUsData.persons[currentPage];

    return (
        <>
            <TextSection isLongText={true} data={data} />
            <Stacks isLong={true} data={stacksData} />
        </>
    );
}
