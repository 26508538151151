import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// Styles
import "../styles/aboutUs.scss";

/**
 * A reusable component to display text with optional button and long text.
 * @param {bool} isLongText - Boolean which specifies if the component will use the short description or the long one
 * @param {object} data - The data which will be parsed: title, shortDescription, longDescription, buttonText, buttonPath
 * @returns - JSX representation of the component.
 */

export default function TextSection({ isLongText = false, data }) {
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Function to update viewport width in state
        const handleResize = () => {
            setViewportWidth(window.innerWidth);
        };

        // Add event listener for window resize
        window.addEventListener("resize", handleResize);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []); // Empty dependency array ensures effect runs only on mount and unmount

    return (
        <section className="text-component component">
            <div className="title-container">
                <h2 className="title">
                    {data.title.map((title, index) => (
                        <React.Fragment key={index}>
                            {title + " "}
                            {/* Add <br> based on viewport width */}
                            {index !== data.title.length - 1 &&
                                window.innerWidth < 425 && <br />}
                        </React.Fragment>
                    ))}
                </h2>
            </div>
            <div className="text-container">
                {isLongText ? (
                    // Render multiple paragraphs with <br> tags
                    data.longDescription.map((item, index) => (
                        <React.Fragment key={index}>
                            <p>{item}</p>
                            {index !== data.length - 1 && <br />}
                        </React.Fragment>
                    ))
                ) : (
                    // Render a single paragraph
                    <p>{data.shortDescription}</p>
                )}
            </div>
            {/* Conditionally render the div only if hasButton is true */}
            {!isLongText && (
                <div className="button-container">
                    <div className="text-component-button">
                        <Link to={data.path}>
                            <button className="cta">{data.buttonTitle}</button>
                        </Link>
                    </div>
                </div>
            )}
        </section>
    );
}
