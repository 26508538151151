import React from "react";

import "../styles/HeroBanner.css"; // Assuming you have a CSS file for styling

// Data
import { heroBannerData } from "../data/heroBannerData";

const HeroBanner = () => {
    return (
        <section className="hero-banner component">
            <img
                src={heroBannerData.images[0].image}
                alt="Hero Banner"
                className="hero-image"
            />
            <div className="button-container">
                <a href="/contact">
                    <button className="cta">
                        See how we can boost your online presence!
                    </button>
                </a>
            </div>
        </section>
    );
};

export default HeroBanner;
