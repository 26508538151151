import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom"; // Import useNavigate

// Styles
import "../styles/aboutUsImages.scss";

/**
 * A reusable component to display text with optional button and long text.
 * @param {object} data - The data which will be parsed: title, shortDescription, longDescription, buttonText, buttonPath
 * @returns - JSX representation of the component.
 */
export default function AboutUsImages({ data }) {
    const displayImages = data.images;
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        // Function to update viewport width in state
        const handleResize = () => {
            setViewportWidth(window.innerWidth);
        };

        // Add event listener for window resize
        window.addEventListener("resize", handleResize);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []); // Empty dependency array ensures effect runs only on mount and unmount

    // Update handleArrowClick to accept an index parameter
    const handleArrowClick = (index) => {
        navigate(data.images[index].path);
    };

    return (
        <section className="component">
            <div className="aboutus-images-container">
                {displayImages.map((image, index) => (
                    <div
                        className="image-card"
                        key={index}
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                        onClick={() => handleArrowClick(index)}
                        style={{
                            backgroundImage: `url(${image.image})`,
                        }}
                    >
                        <div className="border">
                            <h2>{image.name}</h2>
                            <br />
                            <h3>{image.role}</h3>
                            <div className="icons"></div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}
