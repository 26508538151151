import React, { useRef, useEffect } from "react";
import "../styles/footer.css"; // Import the custom CSS
import AlternativeFooter from "./AlternativeFooter"; // Import the PolyrhythmicSpiral component

export default function Footer() {
    // Get the current page path
    const currentPage = window.location.pathname;

    // Check if the current page is the contact page
    if (currentPage === "/contact") {
        return <AlternativeFooter />; // Render PolyrhythmicSpiral if on the contact page
    }

    // Otherwise, render the default footer content
    return (
        <a href="/contact" className="component footer shiny-cta">
            <div className="relative group footer text-center">
                <h2 className="relative">Let's Talk</h2>
                <div className="footer-overlay"></div>
                <div
                    className="footer-arrow"
                    style={{
                        backgroundColor: "rgb(172, 153, 255)",
                        borderRadius: "100%",
                        boxShadow:
                            "rgba(0, 0, 0, 0.18) 0px 0.602187px 0.602187px -1.25px, rgba(0, 0, 0, 0.16) 0px 2.28853px 2.28853px -2.5px, rgba(0, 0, 0, 0.063) 0px 10px 10px -3.75px",
                        opacity: 1,
                        width: "150px",
                        height: "150px",
                        display: "flex", // Add flex display
                        alignItems: "center", // Center items vertically
                        justifyContent: "center", // Center items horizontally
                    }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 256 256"
                        style={{
                            userSelect: "none",
                            width: "50%", // Adjust size as needed
                            height: "50%", // Adjust size as needed
                            display: "inline-block",
                            fill: "rgb(5, 5, 5)",
                            color: "rgb(5, 5, 5)",
                            flexShrink: 0,
                        }}
                    >
                        <g color="rgb(5, 5, 5)">
                            <path d="M221.66,133.66l-72,72a8,8,0,0,1-11.32-11.32L196.69,136H40a8,8,0,0,1,0-16H196.69L138.34,61.66a8,8,0,0,1,11.32-11.32l72,72A8,8,0,0,1,221.66,133.66Z" />
                        </g>
                    </svg>
                </div>
            </div>
        </a>
    );
}
