import React, { useState, useEffect } from "react";

import "../styles/contact.css";

/**
 * A reusable component to display contact informatikon.
 * @param {object} data - The data which will be parsed
 * @returns - JSX representation of the component.
 */

export default function ContactInfo({ data }) {
    const contactsData = data;
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
    });

    useEffect(() => {
        // Function to update viewport width in state
        const handleResize = () => {
            setViewportWidth(window.innerWidth);
        };

        // Add event listener for window resize
        window.addEventListener("resize", handleResize);

        const script = document.createElement("script");
        script.src = "https://js.hsforms.net/forms/v2.js";
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);

        script.addEventListener("load", () => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore
                window.hbspt.forms.create({
                    portalId: "145189008",
                    formId: "3f4efe98-9708-427b-9a9b-f2783ed2604c",
                    target: "#hubspotForm",
                });
            }
            const form = document.querySelector("#hubspotForm form");
            if (form) {
                const inputs = form.querySelectorAll("input, textarea, select");
                inputs.forEach((input) => {
                    input.style.backgroundColor =
                        "var(--color-element-background)";
                });
            }
        });

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []); // Empty dependency array ensures effect runs only on mount and unmount

    return (
        <>
            <section className="component contact-component">
                <div className="title-container">
                    <div className="title-col-divider">
                        <div className="title-icon-section">
                            {data.titleIcon}
                        </div>
                        <div className="title-section">
                            <h2 className="title">{" " + data.title}</h2>
                        </div>
                    </div>
                </div>
                <div className="contact-grid">
                    {contactsData.fields.map((contactData, index) =>
                        contactData.href ? (
                            <a
                                className="contact-container"
                                key={index}
                                href={contactData.href}
                            >
                                <div className="title-container">
                                    <h4>
                                        {contactData.icon} {contactData.value}
                                    </h4>
                                </div>
                            </a>
                        ) : (
                            <div className="contact-container" key={index}>
                                <div className="title-container">
                                    <h4>
                                        {contactData.icon} {contactData.value}
                                    </h4>
                                </div>
                            </div>
                        )
                    )}
                </div>
            </section>
            <section className="component contact-form">
                <div id="hubspotForm"></div>
            </section>
        </>
    );
}
